/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const GoogleOptimize = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "marketplace/googleOptimize/1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.fluid,
      alt: 'Google Optimize Integration',
      title: 'Integrate Google Optimize with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <Img fluid={photos[0].img} alt={photos[0].alt} title={photos[0].title} />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Google Optimize is one of the most popular A/B testing tools. It will help you optimize
          your website and learn more about the preferences of your users.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Add Google Optimize test variants as custom properties in LiveSession</li>
          <li>Get qualitative insights about your A/B tests</li>
          <li>Learn even more about user behavior</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just copy and paste a piece of code, as described in our{' '}
        <a href="/help/google-optimize-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(GoogleOptimize);

GoogleOptimize.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
